import {
    __,
    buyerWorkspaceActions,
    colors,
    constants,
    currency,
    debounce,
    EventTrack,
    IBoxesPerPalletValue,
    imageActions,
    modalActions,
    notificationsActions,
    parsers,
    PLASTIC_TYPE,
    PRICE_ADJUSTMENT_TYPE,
    PRODUCT_UNIT,
    productActions,
    productService,
    RenderTrack,
    sellerWorkspaceActions,
    sellerWorkspaceService,
    tagActions,
    utils,
  } from 'common-services';
  import * as React from 'react';
  import Dropzone from 'react-dropzone';
  import { useDispatch } from 'react-redux';
  import { Prompt, RouteComponentProps } from 'react-router-dom';
  import { Dispatch } from 'redux';
  
  import config from '../../../../bindings/config';
  import * as userAppActions from '../../../actions/user';
  import { IMAGES } from '../../../assets';
  import * as webConstants from '../../../constants';
  import { AllProductUnitsArray } from '../../../domain/product';
  import { convertToIFile } from '../../../services/file';
  import { resizeImage } from '../../../services/image';
  import { logError } from '../../../services/log';
  import getPath from '../../../util/routes';
  import { priceUnitTranslate } from '../../../util/utils';
  import {
    Button,
    ColumnContainer,
    DatePicker,
    FontIcon,
    Input,
    Radio,
    Ribbon,
    Select,
    SelectWorkingStatus,
    TextArea,
    Tooltip,
  } from '../../atoms';
  import { ActionsModal, Attachment, FormSection, InputWithLabel, PriceGroupTable, PriceItemTable } from '../../molecules';
  import { Sections } from '../../molecules/FormMenu/FormMenu.component';
  import {
    getCategories,
    getCountries,
    getOrganicTypes,
    getProductTypes,
    MAX_TAG_LENGTH,
    MAX_UPLOAD_PICTURES,
  } from './ProductEdit.constants';
  import * as S from './ProductEdit.styled';
  import { validateProductForm } from './ProductEdit.utils';
  import Sustainability from './Sustainability';

const PlasticModal: React.FC<{
    product: IProduct;
    save: (p: IProduct) => void;
    close: () => void;
    language: string;
    defaultWeightUnit: WEIGHT_UNIT;
    prodTypes: Record<string, IProdType>;
  }> = ({ product, close, save, language, defaultWeightUnit, prodTypes }) => {
    const [plasticType, setPlasticType] = React.useState(product.plasticType);
    const [plasticUnit, setPlasticUnit] = React.useState(product.plasticUnit);
    const [plasticAmount, setPlasticAmount] = React.useState(product.plasticAmount);
    return (
      <S.ActionsModal
        title={__('ProductEdit.Sustainability.plastic.modal_title', {
          name: productService.getProductTypeVarietyDisplay(
            product.type.type,
            prodTypes[product.type.type] ? prodTypes[product.type.type].name : '',
            product.type.variety,
          ),
        })}
        onClose={close}
        minHeight="auto"
      >
        <S.ModalContainer>
          <InputWithLabel label={__('ProductEdit.Sustainability.plastic.plastic_type')} isRequired={true} width="100%">
            <Select
              containerMargin="4px 0"
              hasError={false}
              name={'plasticType'}
              onChange={(key, value, error) => setPlasticType(value as PLASTIC_TYPE)}
              options={Object.values(PLASTIC_TYPE).map(u => ({ label: constants.getPlasticLiteral(u), value: u }))}
              disabled={false}
              value={plasticType}
              width="100%"
            />
          </InputWithLabel>
          <S.SubRow>
            <InputWithLabel label={__('ProductEdit.Sustainability.plastic.plastic_unit')} isRequired={true} width="110%">
              <Select
                containerMargin="4px 0"
                hasError={false}
                name={'plasticUnit'}
                onChange={(key, value, error) => setPlasticUnit(value as PRODUCT_UNIT)}
                options={Object.values(PRODUCT_UNIT).map(u => ({
                  label: constants.getPriceUnitLabels(u, language, defaultWeightUnit),
                  value: u,
                }))}
                disabled={false}
                value={plasticUnit}
                width="90%"
              />
            </InputWithLabel>
            <InputWithLabel label={__('ProductEdit.Sustainability.plastic.plastic_amount')} isRequired={true} width="90%">
              <Input
                name={'plasticAmount'}
                containerMargin={'4px 0'}
                width="100%"
                type={'number'}
                value={plasticAmount}
                hasError={false}
                onBlur={(key, value, error) => setPlasticAmount(Number(value))}
                isRequired={true}
                disabled={false}
                minValue={0}
                variableTextPlural="g"
              />
            </InputWithLabel>
          </S.SubRow>
          <S.CenteredButton
            type="principal"
            onClick={() => {
              save({ ...product, plasticType, plasticUnit, plasticAmount });
              close();
            }}
            disabled={
              product.plasticAmount === plasticAmount &&
              product.plasticType === plasticType &&
              product.plasticUnit === plasticUnit
            }
          >
            {__('Components.ProductDetails.save')}
          </S.CenteredButton>
        </S.ModalContainer>
      </S.ActionsModal>
    );
  };

  export default PlasticModal;