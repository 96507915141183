import { __ } from 'common-services';
import * as React from 'react';
import SelectSearch, { Props as SelectProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import theme from '../../../theme';
import Tooltip from '../Tooltip';
import * as S from './SelectSearch.styled';

interface IProps {
  autoFocus?: boolean;
  containerMargin?: string;
  creatable?: boolean;
  CustomOption?: (innerProps: any, data: any, isSelected?: boolean) => React.ReactElement;
  defaultMenuIsOpen?: boolean;
  disabled?: boolean;
  dropUp?: boolean;
  fontSize?: string;
  hasError?: boolean;
  innerPadding?: string;
  innerRef?: (ref: any) => void;
  isSearchable?: boolean;
  maxWidth?: string;
  menuWidth?: string;
  minWidth?: string;
  name?: string;
  noBorder?: boolean;
  noOptionsMessage?: string;
  onChange: (name: string, value: string, error?: string, label?: string) => void;
  onInputChange?: (text: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  options: Array<any>;
  placeholder?: string;
  tabIndex?: number;
  value: string;
  width?: string;
  zeroCase?: string;
  zIndex?: number;
}

export default React.memo(
  ({
    autoFocus,
    containerMargin = '15px 0',
    creatable = false,
    CustomOption,
    defaultMenuIsOpen,
    disabled,
    dropUp,
    fontSize,
    hasError,
    innerPadding = '0 0 0 5px',
    innerRef,
    isSearchable = true,
    maxWidth,
    menuWidth = '100%',
    minWidth,
    name = '',
    noBorder,
    noOptionsMessage = __('SelectSearch.no_options'),
    onChange,
    onInputChange,
    onKeyDown,
    options,
    placeholder = '',
    tabIndex,
    value,
    width,
    zeroCase,
    zIndex,
  }: IProps) => {
    const op = Array.isArray(options) ? options.map((option, i) => {
      let val: string = '';
      let l: string = '';
    
      if (typeof option === 'string') {
        val = option;
        l = option;
      } else if (typeof option === 'number') {
        val = option.toString();
        l = option.toString();
      } else if (typeof option === 'object' && option !== null) {
        val = option.value || '';
        l = option.label || '';
      }
      return { ...option, value: val, label: l };
    }) : [];    
    const v = op?.find(o => o.value === value);
    if (disabled) {
      return (
        <S.ReadOnlyText fontSize={fontSize} padding={innerPadding}>
          {(v && v.label) || zeroCase}
        </S.ReadOnlyText>
      );
    }
    const SelectComponent = (p: SelectProps) =>
      creatable ? (
        <CreatableSelect
          {...p}
          inputId={name && `input-${name}`}
          id={name && `id-${name}`}
          instanceId={name && `instance-${name}`}
          formatCreateLabel={inputValue => __('SelectSearch.create_value', { inputValue })}
        />
      ) : (
        <SelectSearch
          {...p}
          inputId={name && name + 'input'}
          id={name && name + 'id'}
          instanceId={name && name + 'instance'}
          maxMenuHeight={300}
          captureMenuScroll={false}
        />
      );
    return (
      <SelectComponent
        tabIndex={tabIndex?.toString() || '0'}
        ref={innerRef}
        defaultMenuIsOpen={defaultMenuIsOpen}
        components={{
          Option: CustomOption
            ? CustomOption
            : ({ innerProps, data, isSelected }) => (
                <S.OptionRow
                  {...innerProps}
                  isSelected={isSelected}
                  data-testid={'option.' + data.value}
                  tabIndex={undefined}
                >
                  {data.tooltip ? (
                    <Tooltip text={data.tooltip} position={data.tooltip_position} themeMode="dark" keepPropagation>
                      <S.TextOption>{data.label}</S.TextOption>
                    </Tooltip>
                  ) : (
                    <S.TextOption>{data.label}</S.TextOption>
                  )}
                </S.OptionRow>
              ),
        }}
        styles={{
          container: (provided, state) => ({
            ...provided,
            width: width || '97%',
            margin: containerMargin || '0',
            maxWidth: maxWidth || 'none',
            minWidth: minWidth || 'none',
          }),
          control: (provided, state) => ({
            ...provided,
            minHeight: '36px',
            maxHeight: '36px',
            border: noBorder
              ? 'none'
              : state.isFocused
              ? `1px solid ${theme.colors.green1} !important`
              : `1px solid ${theme.colors.grey2}`,
            boxShadow: 'none',
            backgroundColor: theme.colors.white,
            '& > div': {
              maxHeight: '34px',
              padding: innerPadding,
              minHeight: '34px',
              position: 'initial',
            },
            ...(hasError
              ? {
                  outline: 'none !important',
                  border: '1px solid ' + theme.colors.red1,
                  boxShadow: `0 0 4px ${theme.colors.grey3}`,
                }
              : {}),
          }),
          input: (provided, state) => ({
            ...provided,
            top: '-1px',
            '& > div': {
              fontFamily: 'inherit',
              fontSize: fontSize || theme.fontSize.big,
              fontWeight: theme.fontWeight.Light,
            },
            '& > div > input': {
              fontFamily: 'inherit',
              fontSize: fontSize || theme.fontSize.big,
              fontWeight: theme.fontWeight.Light,
            },
          }),
          singleValue: (provided, state) => ({
            ...provided,
            fontFamily: 'inherit',
            fontSize: fontSize || theme.fontSize.big,
            maxWidth: CustomOption ? 'calc(100% - 69px)' : 'calc(100% - 43px)',
            marginLeft: CustomOption ? 0 : 2,
            fontWeight: theme.fontWeight.Light,
          }),
          placeholder: (provided, state) => ({
            ...provided,
            fontFamily: 'inherit',
            fontSize: fontSize || theme.fontSize.big,
            color: theme.colors.black,
            fontWeight: theme.fontWeight.Light,
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            maxHeight: '36px',
            overflow: 'hidden',
            '& > span': { margin: 0, height: '16px', alignSelf: 'center' },
            '& > div': { padding: '0 8px', alignItems: 'center', justifyContent: 'center' },
          }),
          menu: (provided, state) => ({
            ...provided,
            top: dropUp ? 'auto' : provided.top,
            bottom: dropUp ? '100%' : provided.bottom,
            width: menuWidth,
            overflowX: 'hidden',
            zIndex: zIndex ? zIndex : 1,
          }),
          menuList: (provided, state) => ({
            ...provided,
            fontFamily: 'inherit',
            fontSize: fontSize || theme.fontSize.big,
            minWidth: 'max-content',
            top: dropUp ? 'auto' : provided.top,
            bottom: dropUp ? '100%' : provided.bottom,
          }),
          option: (provided, state) => {
            return {
              ...provided,
              textAlign: 'left',
              minHeight: '36px',
              maxHeight: '36px',
              backgroundColor: state.isSelected
                ? theme.colors.grey3
                : state.isFocused
                ? theme.colors.grey4
                : theme.colors.white,
              color: theme.colors.black,
              ':hover': {
                backgroundColor: state.isSelected ? theme.colors.grey3 : theme.colors.grey4,
              },
            };
          },
        }}
        options={op}
        value={v}
        onChange={e => handleChange((e as any).value, (e as any).label)}
        onInputChange={newValue => onInputChange?.(newValue)}
        isDisabled={disabled}
        isSearchable={isSearchable}
        placeholder={placeholder}
        autoFocus={autoFocus}
        noOptionsMessage={({ inputValue }) => noOptionsMessage}
        onKeyDown={onKeyDown}
      />
    );

    /**
     * Handle change when a value is selected
     */
    function handleChange(val: string, label: string) {
      onChange(name, val, '', label);
    }
  },
);
