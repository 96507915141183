import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { __, orderBuyerPreparationActions, userSelectors, utils } from 'common-services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Dispatch } from 'redux';

import config from '../../../../bindings/config';
import { priceUnitTranslator, unitTranslator } from '../../../util/unit';
import { Button } from '../../atoms';
import {
  compareReferenceSummaries,
  getObjectFromBuyerOffers,
  getPairedCell,
  IProps,
  mergeCells,
  offersColumnHelper,
  sortSellers,
} from './Functions';
import * as S from './MultiTable.styled';

// first column is not being pinned
const MultiTable: React.FC<IProps> = ({ buyerOffers, workspaceId, orderBuyerPreparation, selectedDate }) => {
  const toggleOfferToOrder = useSelector(userSelectors.hasToggleEnabled(config.TOGGLE_OFFER_TO_ORDER));
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(false);
  const [timeOut, setTimeOut] = React.useState<NodeJS.Timeout | null>(null);
  const [hoveredCell, setHoveredCell] = React.useState<{
    rowIndex: number | null;
    colIndex: string | null;
    pairedCell: string | null;
  }>({
    rowIndex: null,
    colIndex: null,
    pairedCell: null,
  });
  const [selectedCells, setSelectedCells] = React.useState([]);

  const [clickedCell, setClickedCell] = React.useState({
    rowIndex: null,
    colIndex: null,
  });
  const [warehouseNames, setWarehouseNames] = React.useState<Array<string>>([]);
  const [offersGroupedBySeller, setOffersGroupedBySeller] = React.useState<{}>(
    getObjectFromBuyerOffers(buyerOffers, warehouseNames, setWarehouseNames),
  );
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'buyerReference_name',
      desc: false,
    },
  ]);

  const [sortedOffersGroupedBySellerGlobal, setSortedOffersGroupedBySellerGlobal] =
    React.useState<Record<string, Record<string, any>>>();

  const [expanded, setExpanded] = React.useState<Record<string, boolean>>({});

  React.useEffect(() => {
    const allExpanded = buyerOffers.reduce((acc, offer) => {
      const rowId = `buyerReference_name:${offer?.buyerReference?.name}` || '';
      acc[rowId] = true; // Expand all rows initially
      return acc;
    }, {});

    setExpanded(allExpanded);
  }, [buyerOffers]);

  const dispatch = useDispatch<Dispatch<any>>();

  React.useEffect(() => {
    if (buyerOffers.length > 0) {
      setWarehouseNames([]);
      setOffersGroupedBySeller(getObjectFromBuyerOffers(buyerOffers, warehouseNames, setWarehouseNames));
    }
  }, [buyerOffers]);

  React.useEffect(() => {
    if (offersGroupedBySeller) {
      setSortedOffersGroupedBySellerGlobal(sortSellers(offersGroupedBySeller));
    }
  }, [offersGroupedBySeller]);

  React.useEffect(() => {
    setSelectedCells([]);
  }, [selectedDate]);

  const groupOfferIntoColumns = () => {
    const sortedOffersGroupedBySeller = sortSellers(offersGroupedBySeller);
    const groupedColumns = Object.keys(sortedOffersGroupedBySeller).flatMap(sellerName => {
      const sellerOffers = sortedOffersGroupedBySeller[sellerName];
      // Create columns for each seller
      return offersColumnHelper.group({
        id: `Seller-${sellerOffers.seller.name}`,
        header: () => (
          <S.GreyCell className="seller">
            <S.SellerImage
              text={sellerOffers.seller.name}
              avatarColor={utils.getAvatarColor(sellerOffers.seller.name)}
              img={sellerOffers.seller.logo || ''}
            />
            <S.BlackText>{sellerOffers.seller.name}</S.BlackText>
          </S.GreyCell>
        ),
        columns: [
          offersColumnHelper.accessor(
            row =>
              row.unlimited
                ? __('Components.OffersList.availability')
                : `${row.quantity} ${unitTranslator(row.quantityUnit, row.quantity)}`,
            {
              id: `quantity:${sellerName}`, // required for mergeCells function
              size: 200,
              header: property => {
                return (
                  <S.HeaderCell noBorderRight>
                    <S.TextGrey>{__('Components.OffersList.table.availability')}</S.TextGrey>
                  </S.HeaderCell>
                );
              },
              cell: info => {
                const { getValue } = info;
                if (getValue() === '' || parseFloat(getValue().split('-')[0]) === 0) {
                  return '';
                } else {
                  return (
                    <div>
                      <S.BodyText>{getValue().split('-')[0]} </S.BodyText>
                      <S.UnitText>{getValue().split('-')[1]}</S.UnitText>
                    </div>
                  );
                }
              },
              aggregationFn: mergeCells(
                row =>
                  row.unlimited
                    ? __('Components.OffersList.noLimit')
                    : row.totalQuantity
                    ? row.totalQuantity + '-' + unitTranslator(row.totalQuantityUnit, row.totalQuantity)
                    : row.quantity + '-' + unitTranslator(row.quantityUnit, row.quantity),
                filteredRows =>
                  filteredRows.reduce(
                    (acc, row) => {
                      acc.quantity += row.original.quantity;
                      if (!acc.unlimited) acc.unlimited = row.original.unlimited;
                      return acc;
                    },
                    { ...filteredRows[0].original, quantity: 0 },
                  ),
              ),
            },
          ),
          offersColumnHelper.accessor(row => `${row.price}${row.priceUnit}`, {
            id: `price:${sellerName}`, // required for mergeCells function
            header: () => (
              <S.HeaderCell noBorderLeft noBorderRight>
                <S.TextGrey>{__('Components.OffersList.table.price')}</S.TextGrey>
              </S.HeaderCell>
            ),
            cell: info => {
              const { getValue } = info;
              if (getValue() === '' || parseFloat(getValue().split('-')[0]) === 0) {
                return '';
              } else {
                return (
                  <div>
                    <S.BodyText>{getValue().split('-')[0]} </S.BodyText>
                    <S.UnitText>{getValue().split('-')[1]}</S.UnitText>
                  </div>
                );
              }
            },
            aggregationFn: mergeCells(
              row =>
                (row.offeredDeparturePrice ? row.offeredDeparturePrice.toFixed(2) : 0) +
                '-' +
                priceUnitTranslator(row.priceUnit),
            ),
          }),
        ],
      });
    });

    const handleRowToggle = row => {
      row.toggleExpanded();
    };

    const sellerProductNameColumn = offersColumnHelper.accessor('buyerReference.name', {
      header: () => (
        <S.HeaderCell noBorderRight noBorderLeft>
          <S.TextGrey>{__('Components.OffersList.table.references')}</S.TextGrey>
        </S.HeaderCell>
      ),
      cell: info => {
        // console.log('info :>> ', info);
        return (
          <S.ContainerReference>
            <S.ContainerReferenceInfo>
              <S.ArrowIconInfo
                name={info.row.getIsExpanded() ? 'Down' : 'Right'}
                onClick={() => handleRowToggle(info.row)}
              />
            </S.ContainerReferenceInfo>
            <S.ContainerReferenceInfo>
              <S.BlackText>{info.getValue()}</S.BlackText>
            </S.ContainerReferenceInfo>
          </S.ContainerReference>
        );
      },
      sortingFn: (rowA, rowB) => {
        return compareReferenceSummaries(rowA.original.buyerReference, rowB.original.buyerReference);
      },
    });

    // add `sellerProductNameColumn` at the start
    groupedColumns.unshift(sellerProductNameColumn);

    return groupedColumns;
  };

  const table = useReactTable({
    data: buyerOffers,
    columns: groupOfferIntoColumns(),
    state: {
      sorting,
      grouping: React.useMemo(() => ['buyerReference_name'], []),
      expanded,
    },
    manualExpanding: true,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded, // Handle row expansion changes
    onSortingChange: setSorting,
    enableColumnResizing: false,
    defaultColumn: {
      size: 150,
    },
  });

  const handleInputChange = () => {
    setIsScrolled(true);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    const timeout = setTimeout(() => {
      setIsScrolled(false);
    }, 400);
    setTimeOut(timeout);
  };

  const handleCellClick = cell => {
    if (!cell.pairedCell) return;
    setSelectedCells(prevSelectedCells => {
      const isSelected = prevSelectedCells.some(selected => selected.cellId === cell.cellId);
      if (isSelected) {
        return prevSelectedCells.filter(selected => selected.cellId !== cell.cellId);
      } else {
        return [...prevSelectedCells, cell];
      }
    });
  };

  const clearSelectedCells = () => {
    setSelectedCells([]);
  };
  const float = () => {
    return (
      <S.FloatContainer>
        <S.CancelText onClick={clearSelectedCells}>{__('Components.OffersList.table.cancelText')}</S.CancelText>
        <Button
          onClick={() => {
            const offeredProducts = selectedCells.reduce((ids, cell) => {
              const id = cell.cellId;
              const seller = id.split(':')[1];
              const ref = id.split('Seller-')[1];
              const sellerName = seller.split('Seller-')[0];

              buyerOffers.forEach(offer => {
                if (offer.seller.name === ref && offer.buyerReference.name === sellerName) {
                  ids.push(offer.offeredProductId);
                }
              });

              return ids;
            }, []);
            const orderBuyerPreparationBody = {
              deliveryDate: selectedDate.split('T')[0],
              offeredProducts,
            };
            if (orderBuyerPreparation) {
              dispatch(
                orderBuyerPreparationActions.orderBuyerPreparationAddLines(
                  workspaceId,
                  orderBuyerPreparation.id,
                  orderBuyerPreparationBody,
                ),
              );
            } else {
              const offeredProducts = selectedCells.map(cell => cell.rowData.offeredProductId);
              const newOrderBuyerPreparation = {
                deliveryDate: selectedDate.split('T')[0],
                offeredProducts,
              };
              dispatch(orderBuyerPreparationActions.orderBuyerPreparationCreate(workspaceId, newOrderBuyerPreparation));
            }
          }}
        >
          {' '}
          {__('Components.OffersList.table.selection', {
            selection: selectedCells.length,
          })}
        </Button>
      </S.FloatContainer>
    );
  };

  let numberRow = 0;
  const showFloat = selectedCells.length > 0;
  return (
    <S.StyledContainer onScroll={handleInputChange}>
      <Prompt when={showFloat} message={__('Components.ProductDetails.confirm_exit_changes')} />
      <S.StyledTable isScrolled={isScrolled}>
        <thead>
          {table.getHeaderGroups().map((headerGroup, i) => (
            <tr key={headerGroup.id + i}>
              {headerGroup.headers.map((header, idx) => (
                <S.StyledTh key={header.id + idx} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </S.StyledTh>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => {
            numberRow += 1;
            return (
              <React.Fragment key={row.id + rowIndex}>
                <tr>
                  {row.getVisibleCells().map((cell, idx) => {
                    const isPriceCell = cell.column.id.startsWith('price:');
                    const { id: colIndex } = cell.column;
                    const pairedCell = getPairedCell(cell, idx, row);
                    const rowData = row.original;
                    const isReferenceCell = cell.column.id.startsWith('buyerReference');
                    const cellId = cell.row?.id + cell.column?.parent?.id;
                    return (
                      <S.StyledCell
                        key={cell.id + idx}
                        isEndCell={isPriceCell}
                        isCellGrey={numberRow % 2 === 0}
                        className={`${isReferenceCell ? 'reference' : ''} ${
                          selectedCells.some(
                            selected =>
                              selected.rowIndex === rowIndex &&
                              !isReferenceCell &&
                              (selected.colIndex === colIndex || selected.colIndex === pairedCell),
                          )
                            ? 'selected'
                            : ''
                        } ${
                          hoveredCell.rowIndex === rowIndex &&
                          !isReferenceCell &&
                          (hoveredCell.colIndex === colIndex || hoveredCell.colIndex === pairedCell)
                            ? 'hovered'
                            : ''
                        }`}
                        style={{ position: cell.column.id.startsWith('price:') ? 'relative' : 'sticky' }}
                        onPointerEnter={() => setHoveredCell({ rowIndex, colIndex, pairedCell })}
                        onPointerLeave={() => setHoveredCell({ rowIndex: null, colIndex: null, pairedCell: null })}
                        onClick={() => {
                          if (cell.getValue() && toggleOfferToOrder) {
                            handleCellClick({ cellId, rowIndex, colIndex, pairedCell, rowData });
                          }
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        {hoveredCell.rowIndex === rowIndex &&
                          (hoveredCell.pairedCell === colIndex || hoveredCell.colIndex === colIndex) &&
                          colIndex.startsWith('price') && (
                            <S.TooltipContainer
                              text={__('Components.OffersList.table.tooltipBoxPerPallet', {
                                boxesPerPallet:
                                  rowData.boxPerPallet === 0 ||
                                  rowData.boxPerPallet === null ||
                                  rowData.boxPerPallet === undefined
                                    ? ''
                                    : rowData.boxPerPallet,
                              })}
                              themeMode="dark"
                              position="right"
                              keepPropagation={true}
                            >
                              <S.FontIconInfo name="Info" />
                            </S.TooltipContainer>
                          )}
                      </S.StyledCell>
                    );
                  })}
                </tr>
                {row.getIsExpanded() &&
                  warehouseNames.map((warehouseItem, index) => {
                    const isEven = rowIndex % 2 === 0;
                    // If a row doesnt have availability and price for all of the sellers, we don't render it
                    const hasRowData = (product, warehouseItem) => {
                      // Check if the product exists and has availability and price
                      if (!product || !product.warehouses[warehouseItem]) {
                        return false;
                      }
                      const warehouseData = product.warehouses[warehouseItem];
                      const hasAvailability = warehouseData.totalAvailability && warehouseData.totalAvailability > 0;
                      const hasPrice = warehouseData.totalPrice && warehouseData.totalPrice > 0;
                      // If there's availability or price, the row has data
                      return hasAvailability || hasPrice;
                    };

                    const rowHasData = Object.keys(sortedOffersGroupedBySellerGlobal).some(sellerName => {
                      const product =
                        sortedOffersGroupedBySellerGlobal[sellerName].products[row.original.buyerReference.name];
                      return hasRowData(product, warehouseItem);
                    });
                    if (!rowHasData) return null;
                    return (
                      <tr key={row.id + rowIndex + index}>
                        {isEven ? (
                          <S.StyledCellWhite>
                            <S.TextGrey>
                              <S.ArrowIconInfo name="Address" />
                              {warehouseItem}
                            </S.TextGrey>
                          </S.StyledCellWhite>
                        ) : (
                          <S.StyledCellGrey>
                            <S.TextGrey>
                              <S.ArrowIconInfo name="Address" />
                              {warehouseItem}
                            </S.TextGrey>
                          </S.StyledCellGrey>
                        )}

                        {Object.keys(sortedOffersGroupedBySellerGlobal).map((sellerName, indexObject) => {
                          const product =
                            sortedOffersGroupedBySellerGlobal[sellerName].products[row.original.buyerReference.name];
                          // console.log('product.warehouses :>> ', product?.warehouses);
                          return (
                            <React.Fragment key={row.id + rowIndex + index + indexObject}>
                              {isEven ? (
                                <S.StyledCellWhite>
                                  <S.TextGrey>
                                    {product && product.warehouses[warehouseItem]
                                      ? product.warehouses[warehouseItem].totalAvailability === 0
                                        ? ''
                                        : product.warehouses[warehouseItem].totalAvailability +
                                          ' ' +
                                          unitTranslator(
                                            product.warehouses[warehouseItem].offers[0].quantityUnit,
                                            product.warehouses[warehouseItem].totalAvailability,
                                          )
                                      : ''}
                                  </S.TextGrey>
                                </S.StyledCellWhite>
                              ) : (
                                <S.StyledCellGrey>
                                  <S.TextGrey>
                                    {product && product.warehouses[warehouseItem + '']
                                      ? product.warehouses[warehouseItem + ''].totalAvailability === 0
                                        ? ''
                                        : product.warehouses[warehouseItem + ''].totalAvailability +
                                          ' ' +
                                          unitTranslator(
                                            product.warehouses[warehouseItem + ''].offers[0].quantityUnit,
                                            product.warehouses[warehouseItem + ''].totalAvailability,
                                          )
                                      : ''}
                                  </S.TextGrey>
                                </S.StyledCellGrey>
                              )}
                              {isEven ? (
                                <S.StyledCellWhite>
                                  <S.TextGrey>
                                    {product && product.warehouses[warehouseItem + '']
                                      ? product.warehouses[warehouseItem + ''].totalPrice === 0
                                        ? ''
                                        : product.warehouses[warehouseItem + ''].totalPrice.toFixed(2) +
                                          ' ' +
                                          priceUnitTranslator(
                                            product.warehouses[warehouseItem + ''].offers[0].priceUnit,
                                          )
                                      : ''}
                                  </S.TextGrey>
                                </S.StyledCellWhite>
                              ) : (
                                <S.StyledCellGrey>
                                  <S.TextGrey>
                                    {product && product.warehouses[warehouseItem + '']
                                      ? product.warehouses[warehouseItem + ''].totalPrice === 0
                                        ? ''
                                        : product.warehouses[warehouseItem + ''].totalPrice.toFixed(2) +
                                          ' ' +
                                          priceUnitTranslator(
                                            product.warehouses[warehouseItem + ''].offers[0].priceUnit,
                                          )
                                      : ''}
                                  </S.TextGrey>
                                </S.StyledCellGrey>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </tbody>
      </S.StyledTable>
      {showFloat && <S.FloatContainer>{float()}</S.FloatContainer>}
    </S.StyledContainer>
  );
};

export default MultiTable;
