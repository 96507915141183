import {
  __,
  buyerWorkspaceActions,
  colors,
  constants,
  currency,
  debounce,
  EventTrack,
  IBoxesPerPalletValue,
  imageActions,
  modalActions,
  notificationsActions,
  parsers,
  PLASTIC_TYPE,
  PRICE_ADJUSTMENT_TYPE,
  PRODUCT_UNIT,
  productActions,
  productService,
  RenderTrack,
  sellerWorkspaceActions,
  sellerWorkspaceService,
  tagActions,
  utils,
} from 'common-services';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import config from '../../../../bindings/config';
import * as userAppActions from '../../../actions/user';
import { IMAGES } from '../../../assets';
import * as webConstants from '../../../constants';
import { AllProductUnitsArray } from '../../../domain/product';
import { convertToIFile } from '../../../services/file';
import { resizeImage } from '../../../services/image';
import { logError } from '../../../services/log';
import getPath from '../../../util/routes';
import { priceUnitTranslate } from '../../../util/utils';
import {
  Button,
  ColumnContainer,
  DatePicker,
  FontIcon,
  Input,
  Radio,
  Ribbon,
  Select,
  SelectWorkingStatus,
  TextArea,
  Tooltip,
} from '../../atoms';
import { ActionsModal, Attachment, FormSection, InputWithLabel, PriceGroupTable, PriceItemTable } from '../../molecules';
import { Sections } from '../../molecules/FormMenu/FormMenu.component';
import {
  getCategories,
  getCountries,
  getOrganicTypes,
  getProductTypes,
  MAX_TAG_LENGTH,
  MAX_UPLOAD_PICTURES,
} from './ProductEdit.constants';
import * as S from './ProductEdit.styled';
import { validateProductForm } from './ProductEdit.utils';
import Sustainability from './Sustainability';


export function AddToPriceGroup({
    contacts,
    isQuoterMode,
    me,
    priceGroups,
    pricesAddProduct,
    product,
    setShowAddToPriceGroup,
  }: {
    contacts: Record<number, IContact>;
    isQuoterMode: boolean;
    me: IUser;
    priceGroups: Array<IPriceGroup>;
    pricesAddProduct: (selecteds: Array<string>) => void;
    product: IProduct;
    setShowAddToPriceGroup: (show: boolean) => void;
  }) {
    const [searchText, setSearchText] = React.useState('');
    const [selecteds, setSelecteds] = React.useState([]);
    return (
      <ActionsModal
        onClose={() => setShowAddToPriceGroup(false)}
        title={
          isQuoterMode
            ? __('Components.ProductDetails.add_to_margins')
            : __('Components.ProductDetails.add_to_pricegroups')
        }
        width="800px"
      >
        <S.SearchWrapper>
          <S.PriceGroupSearch
            id="search-price-groups"
            query={searchText}
            placeHolder={
              isQuoterMode ? __('WorkspaceMargins.search_placeholder') : __('WorkspacePriceGroups.search_placeholder')
            }
            onChange={(t: string) => setSearchText(t)}
          />
        </S.SearchWrapper>
        <S.TableContainer className="price-groups-scroll">
          <PriceGroupTable
            className="product-price-group-table"
            contacts={contacts}
            isQuoterMode={isQuoterMode}
            me={me}
            onItemClick={p => {
              const { priceGroupId } = p;
              if (selecteds.includes(priceGroupId)) {
                const s = [...selecteds];
                s.splice(s.indexOf(priceGroupId), 1);
                setSelecteds(s);
              } else {
                setSelecteds([...selecteds, priceGroupId]);
              }
            }}
            priceGroups={searchText ? priceGroups.filter(p => p.name.includes(searchText)) : priceGroups}
            product={product}
            selecteds={selecteds}
            touchImage={() => null}
          />
        </S.TableContainer>
        {selecteds.length ? (
          <S.AddButton
            type="principal"
            onClick={() => {
              pricesAddProduct(selecteds);
              setSelecteds([]);
            }}
          >
            {isQuoterMode
              ? __('Components.ProductDetails.add_to_margins_cta', { count: selecteds.length })
              : __('Components.ProductDetails.add_to_pricegroups_cta', { count: selecteds.length })}
          </S.AddButton>
        ) : null}
      </ActionsModal>
    );
  }
  