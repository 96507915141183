import {
    __,
    buyerWorkspaceActions,
    colors,
    constants,
    currency,
    debounce,
    EventTrack,
    IBoxesPerPalletValue,
    imageActions,
    modalActions,
    notificationsActions,
    parsers,
    PLASTIC_TYPE,
    PRICE_ADJUSTMENT_TYPE,
    PRODUCT_UNIT,
    productActions,
    productService,
    RenderTrack,
    sellerWorkspaceActions,
    sellerWorkspaceService,
    tagActions,
    utils,
  } from 'common-services';
  import * as React from 'react';
  import Dropzone from 'react-dropzone';
  import { useDispatch } from 'react-redux';
  import { Prompt, RouteComponentProps } from 'react-router-dom';
  import { Dispatch } from 'redux';
  
  import config from '../../../../bindings/config';
  import * as userAppActions from '../../../actions/user';
  import { IMAGES } from '../../../assets';
  import * as webConstants from '../../../constants';
  import { AllProductUnitsArray } from '../../../domain/product';
  import { convertToIFile } from '../../../services/file';
  import { resizeImage } from '../../../services/image';
  import { logError } from '../../../services/log';
  import getPath from '../../../util/routes';
  import { priceUnitTranslate } from '../../../util/utils';
  import {
    Button,
    ColumnContainer,
    DatePicker,
    FontIcon,
    Input,
    Radio,
    Ribbon,
    Select,
    SelectWorkingStatus,
    TextArea,
    Tooltip,
  } from '../../atoms';
  import { ActionsModal, Attachment, FormSection, InputWithLabel, PriceGroupTable, PriceItemTable } from '../../molecules';
  import { Sections } from '../../molecules/FormMenu/FormMenu.component';
  import {
    getCategories,
    getCountries,
    getOrganicTypes,
    getProductTypes,
    MAX_TAG_LENGTH,
    MAX_UPLOAD_PICTURES,
  } from './ProductEdit.constants';
  import * as S from './ProductEdit.styled';
  import { validateProductForm } from './ProductEdit.utils';
  import Sustainability from './Sustainability';
import AdditionalFilesModal from './AdditionalFilesModal.component';

const AdditionalFiles: React.FC<{
    me: IUser;
    product: IProduct;
    additionalFilesRef: React.RefObject<HTMLDivElement>;
    updateProduct: (p: IProduct) => void;
  }> = ({ me, product, additionalFilesRef, updateProduct }) => {
    const [showDeleteAttachmentButton, setShowDeleteAttachmentButton] = React.useState(0);
    const [showAdditionalFilesModal, setShowAdditionalFilesModal] = React.useState(false);
    const dispatch = useDispatch<Dispatch<any>>();
    return (
      <>
        <FormSection
          id="additional-files"
          title={__('ProductEdit.additionalFiles.title')}
          sectionRef={additionalFilesRef}
          withoutPaddingTop={true}
          numHeaders={product.id ? undefined : 2}
        >
          <S.GreyText>{__('ProductEdit.additionalFiles.subtitle')}</S.GreyText>
          {product.attachments.length > 0 ? (
            <>
              {product.attachments.map((att, idx) => {
                return (
                  <S.AttachmentWrapper key={idx} onMouseLeave={() => setShowDeleteAttachmentButton(0)}>
                    <Attachment
                      key={idx}
                      attachment={att}
                      download={download => {
                        try {
                          dispatch(productActions.getProductFile(me.id, product?.id, att.id, download));
                        } catch (err) {
                          logError(err, 'Error, unable to download attachment');
                        }
                      }}
                    />
                    {showDeleteAttachmentButton === att?.id ? (
                      <S.DeleteAtt className="trash" onClick={() => onDeleteAttachment(att)}>
                        <S.TrashIcon name="Trash" />{' '}
                        <S.DeleteAttText>{__('ProductEdit.additionalFiles.delete.title')}</S.DeleteAttText>
                      </S.DeleteAtt>
                    ) : null}
                    <S.KebabIcon
                      name="Kebab"
                      className="kebab"
                      onClick={() =>
                        showDeleteAttachmentButton === 0
                          ? setShowDeleteAttachmentButton(att?.id)
                          : setShowDeleteAttachmentButton(0)
                      }
                    />
                  </S.AttachmentWrapper>
                );
              })}
            </>
          ) : null}
  
          <S.AddMoreCta
            iconName={'Add-more'}
            type="link"
            onClick={() => setShowAdditionalFilesModal(true)}
            withoutPadding
          >
            {__('ProductEdit.additionalFiles.link')}
          </S.AddMoreCta>
        </FormSection>
  
        {showAdditionalFilesModal ? (
          <AdditionalFilesModal
            product={product}
            userId={me.id}
            close={() => setShowAdditionalFilesModal(false)}
            save={(p, f) => updateProduct({ ...product, attachments: [...product.attachments, f] })}
          />
        ) : null}
      </>
    );
  
    function onDeleteAttachment(att: IAttachment) {
      setShowDeleteAttachmentButton(0);
      dispatch(
        modalActions.modalOpen(
          __('ProductEdit.additionalFiles.delete.title'),
          () => {
            try {
              dispatch(
                productActions.productDeleteFile(me.id, product.id, att.id, error => {
                  this.save();
                }),
              );
            } catch (err) {
              logError(err, 'ProductEdit.uploadFile');
            }
            dispatch(modalActions.modalClose());
          },
          {
            text2: __('ProductEdit.additionalFiles.delete.text'),
            showCancelButton: true,
            buttonText: __('ProductEdit.additionalFiles.delete.cta'),
            actionType: 'dangerous',
            icon: IMAGES.error,
            closeable: true,
          },
          'nice',
        ),
      );
    }
  };

  export default AdditionalFiles;