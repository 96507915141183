import {
    __,
    buyerWorkspaceActions,
    colors,
    constants,
    currency,
    debounce,
    EventTrack,
    IBoxesPerPalletValue,
    imageActions,
    modalActions,
    notificationsActions,
    parsers,
    PLASTIC_TYPE,
    PRICE_ADJUSTMENT_TYPE,
    PRODUCT_UNIT,
    productActions,
    productService,
    RenderTrack,
    sellerWorkspaceActions,
    sellerWorkspaceService,
    tagActions,
    utils,
  } from 'common-services';
  import * as React from 'react';
  import Dropzone from 'react-dropzone';
  import { useDispatch } from 'react-redux';
  import { Prompt, RouteComponentProps } from 'react-router-dom';
  import { Dispatch } from 'redux';
  
  import config from '../../../../bindings/config';
  import * as userAppActions from '../../../actions/user';
  import { IMAGES } from '../../../assets';
  import * as webConstants from '../../../constants';
  import { AllProductUnitsArray } from '../../../domain/product';
  import { convertToIFile } from '../../../services/file';
  import { resizeImage } from '../../../services/image';
  import { logError } from '../../../services/log';
  import getPath from '../../../util/routes';
  import { priceUnitTranslate } from '../../../util/utils';
  import {
    Button,
    ColumnContainer,
    DatePicker,
    FontIcon,
    Input,
    Radio,
    Ribbon,
    Select,
    SelectWorkingStatus,
    TextArea,
    Tooltip,
  } from '../../atoms';
  import { ActionsModal, Attachment, FormSection, InputWithLabel, PriceGroupTable, PriceItemTable } from '../../molecules';
  import { Sections } from '../../molecules/FormMenu/FormMenu.component';
  import {
    getCategories,
    getCountries,
    getOrganicTypes,
    getProductTypes,
    MAX_TAG_LENGTH,
    MAX_UPLOAD_PICTURES,
  } from './ProductEdit.constants';
  import * as S from './ProductEdit.styled';
  import { validateProductForm } from './ProductEdit.utils';
  import Sustainability from './Sustainability';

const AdditionalFilesModal: React.FC<{
    product: IProduct;
    userId: number;
    save: (p: IProduct, f: IAttachment) => void;
    close: () => void;
  }> = ({ product, userId, close, save }) => {
    const dispatch = useDispatch<Dispatch<any>>();
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
  
    const modifyFiles = React.useCallback(
      existingFiles => {
        const fileToUpload = [...existingFiles];
  
        uploadedFiles.forEach(file => {
          fileToUpload.push(file);
        });
        return fileToUpload;
      },
      [uploadedFiles],
    );
  
    const setFiles = React.useCallback(
      (files: Array<File>) => {
        if (files.length > 0) {
          setUploadedFiles(modifyFiles(files));
        }
      },
      [modifyFiles],
    );
    return (
      <S.ActionsModal title={__('ProductEdit.additionalFiles.modal.title')} onClose={close} minHeight="auto">
        <S.TextModal>{__('ProductEdit.additionalFiles.modal.subtitle')}</S.TextModal>
        {/* <FileDropzone
          files={uploadedFiles}
          setFiles={setFiles}
          close={close}
          onSubmit={() => {
            try {
              convertToIFile(uploadedFiles[uploadedFiles.length - 1], (res: IFile, fileString: string) => {
                dispatch(
                  productActions.productUploadFile(userId, product.id, res, (attachment, error) => {
                    save(product, attachment);
                  }),
                );
              });
            } catch (err) {
              logError(err, 'ProductEdit.uploadFile');
            }
            close();
          }}
        /> */}
      </S.ActionsModal>
    );
  };

  export default AdditionalFilesModal;